exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-about-js": () => import("./../../../src/pages/sections/about.js" /* webpackChunkName: "component---src-pages-sections-about-js" */),
  "component---src-pages-sections-download-js": () => import("./../../../src/pages/sections/download.js" /* webpackChunkName: "component---src-pages-sections-download-js" */),
  "component---src-pages-sections-features-js": () => import("./../../../src/pages/sections/features.js" /* webpackChunkName: "component---src-pages-sections-features-js" */),
  "component---src-pages-sections-home-js": () => import("./../../../src/pages/sections/home.js" /* webpackChunkName: "component---src-pages-sections-home-js" */),
  "component---src-pages-sections-modes-js": () => import("./../../../src/pages/sections/modes.js" /* webpackChunkName: "component---src-pages-sections-modes-js" */),
  "component---src-pages-sections-pricing-js": () => import("./../../../src/pages/sections/pricing.js" /* webpackChunkName: "component---src-pages-sections-pricing-js" */),
  "component---src-pages-sections-qa-js": () => import("./../../../src/pages/sections/qa.js" /* webpackChunkName: "component---src-pages-sections-qa-js" */),
  "component---src-pages-sections-testimonial-js": () => import("./../../../src/pages/sections/testimonial.js" /* webpackChunkName: "component---src-pages-sections-testimonial-js" */)
}

